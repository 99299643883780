<template>
  <div class="payment-email">
    <div class="p-0 payment-email-content">
      <div class="col-12 mx-auto">
        <p>Ingresa tu correo electrónico para continuar la compra</p>
      </div>
      <div
        class="col-12 mx-auto d-flex flex-wrap justify-content-between position-relative"
      >
        <input
          id="email"
          v-model="email"
          class="payment-input payment-email-input mb-4"
          placeholder="Correo electrónico"
          type="email"
        />
        <span v-if="email" class="newlatter-error-input"
          >Correo electrónico</span
        >
        <div
          class="d-flex d-md-none mb-4 align-items-center justify-content-center"
        >
          <label class="d-flex mx-0 ms-md-1">
            <input
              v-model="tyc"
              class="tyc-checkbox"
              type="checkbox"
              checkmark="checkmark"
            />
            <span class="checkmark"></span>
          </label>
          <p class="mb-0" style="font-size: 1rem">
            * Acepto
            <router-link
              style="font-size: 1rem; color: #654062;"
              target="_blank"
              :to="{
                name: 'Politicas',
                params: { slug: 'politica-de-privacidad' },
              }"
              >Términos y condiciones y Política de tratamiento de
              datos.</router-link
            >
          </p>
        </div>
        <div class="d-flex d-md-none mb-4 align-items-center">
          <label class="d-flex mx-0 ms-md-1">
            <input
              v-model="mayor18"
              class="tyc-checkbox"
              type="checkbox"
              checkmark="checkmark"
            />
            <span class="checkmark"></span>
          </label>
          <p class="mb-0" style="font-size: 1rem">
            * Confirma que eres mayor de 18 años
          </p>
        </div>
        <button
          :disabled="!tyc || !mayor18"
          :class="tyc && mayor18 ? '' : 'disabled-btn'"
          :title="tyc ? 'continuar' : 'Acepta los terminos y condiciones'"
          class="payment-button-input btn-primary"
          @click="validateEmail()"
        >
          Continuar
        </button>
      </div>
      <p v-if="msgError" class="msgError">Correo invalido</p>
      <p v-if="msgErrorCheckout" class="msgError w-100 mb-4 mt-4 mt-md-0">
        {{ msgErrorCheckout }}
      </p>
      <div class="d-none d-md-flex mb-3 align-items-center">
        <label class="d-flex mx-0 ms-md-1">
          <input
            v-model="tyc"
            class="tyc-checkbox"
            type="checkbox"
            checkmark="checkmark"
          />
          <span class="checkmark"></span>
        </label>
        <p class="mb-0" style="font-size: 1rem">
          * Acepto
          <router-link
            style="font-size: 1rem; color: #654062;"
            target="_blank"
            :to="{
              name: 'Politicas',
              params: { slug: 'politica-de-privacidad' },
            }"
            >Términos y condiciones y Política de tratamiento de
            datos.</router-link
          >
        </p>
      </div>
      <div class="d-none d-md-flex align-items-center mb-2">
        <label class="d-flex mx-0 ms-md-1">
          <input
            v-model="mayor18"
            class="tyc-checkbox"
            type="checkbox"
            checkmark="checkmark"
          />
          <span class="checkmark"></span>
        </label>
        <p class="mb-0" style="font-size: 1rem">
          * Confirmo que soy mayor de 18 años -
          <span class="bold">Ley 1480 de 2011</span>
        </p>
      </div>
      <div
        class="col-12 col-md-5 mx-auto mt-1 d-flex flex-wrap justify-content-center"
      >
        <span v-if="msgError" class="msgError">Correo invalido</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
export default {
  data() {
    return {
      tyc: false,
      mayor18: true,
      email: '',
      msgError: false,
    }
  },
  computed: {
    ...mapState('payment', ['dataCartAnonymous', 'msgErrorCheckout']),
  },
  methods: {
    ...mapActions('payment', ['updateEmailCartAnonymous']),
    async validateEmail() {
      /* eslint-disable */
      this.msgError = false
      let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if (this.email && re.test(this.email) && this.tyc && this.mayor18) {
        this.$store.state.loaderDerek = true
        await this.updateEmailCartAnonymous({
          correo: this.email,
          token: this.$route.params.tokenCart,
        }).then(() => {
          if (
            this.dataCartAnonymous.carrito.cliente_correo &&
            this.dataCartAnonymous.carrito.carrito_editar == 1 && !this.msgErrorCheckout
          ) {
            this.$emit('nextStep')
          } else {
            if (this.dataCartAnonymous.carrito.carrito_editar == 0) {
              this.$emit('maskData')
            }
          }
          this.$store.state.loaderDerek = false
        })
      } else {
        this.msgError = true
      }
    },
  },
}
</script>
